/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
@import "mdx-ivcomp-common-ui/dist/src/styles/iv-styles";
@import '@carbon/styles';
@import '@carbon/type';
@import '@carbon/layout';
@import './pages/LoginPage/login-page';
@import './pages/IpLoginPage/iplogin-page';

@import './pages/ChatbotPage/chatbot-page';
@import './pages/ChatbotPage/chatbot-components/render_turn';
@import './pages/ChatbotPage/chatbot-components/conversation_filters';

@import './pages/DrugPointsPage2/drug-points-page';
@import './pages/DrugPointsPage2/drug-points-components/drug-points-components';

@import './components/xref-modal-popup';


@import './pages/InDepthPage2/in-depth-page';
//@import './pages/DrugInteractionPage/drug-interaction-page';
//@import './pages/DrugInteractionPage/drug-interaction-components/drug-interaction-components';
@import './pages/DrugConsultsPage/drug-consults-page';
@import './pages/DrugInteractionPage/drug-interaction-page';
@import './pages/HomePage/home-page';



@import './components/picklist/_picklist';
@import './pageHeaders/GenericHeader/generic-header';
@import './pageHeaders/ChatbotHeader/chatbot-header';
@import './components/common/footers/generic-footer';
@import './components/schema-based-renderer/schema-based-renderer';
@import './components/schema-based-renderer/fda-uses-modal-popup';
@import './components/subscription/subscirption-notification';
@import './components/standalone-subscription-modal/standalone-subscription-modal';


@import './pages/DrugInteractionPage/drug-interaction-components/filterModal/filter-modal-components';

@import './pages/DrugMonographTabbedPage/drug-monograph-page';

@import "src/components/error/error-popup";

@import "./components/response-loading/response_loading";
@import "styles/iv_override";

@import url('https://fonts.googleapis.com/css2?family=Roboto:&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url('https://fonts.googleapis.com/css?family=Merriweather&display=swap');

// see: https://css-tricks.com/the-trick-to-viewport-units-on-mobile
:root {
  --vh: 1vh;
  --data-column-width: 120;
  --shadow-line-width: 8;
  box-sizing: content-box;
}

html {
  font-size: 16px !important;
}
li a.pwa_link {
    margin: .2rem .2rem .2rem -2.5rem !important;
}
