/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
@import "@carbon/styles";
@import "@carbon/type";
@import "@carbon/layout";

$iv-search-page-footer-border-color: #ccc0c0;

footer {
  width: 100%;
  position: fixed;
  border: 0;
  left: 0;
  bottom: 0;
  height: 6.5rem;
  z-index: 6550;
  background-color: transparent;

  .login-page-footer {
    background-color: rgb(37, 40, 42);

    .small-link {
      font-family: verdana, arial, helvetica, sans-serif;
      font-size: 0.9375rem;
      color: $background;
    }

    tr {
      background-color: rgb(37, 40, 42);
      height: 10%;
    }

    td {
      padding: 0.5rem 0.2rem !important;
    }
  }

  .bottom-chat-action {
    position: fixed;
    bottom: 0.2rem;
    right: 13px; //this many pixels away from edge to give room for scrollbar

    #ask-watson-button {
      padding: 5px;
      margin: 0 0.2rem 0.2rem 0.6rem;
      line-height: $spacing-07;

      .ask-watson-btn {
        padding-left: 7px;
        line-height: 16.5px;
        width: 85px;
      }

      img {
        margin-left: 0.6rem;
        vertical-align: middle;
      }
    }
  }

  .BackToTopBtn-module--button--32mr1 {
    position: fixed;
    right: 13px;
    display: none;
    width: 2.6rem;
    height: 2.4rem;
    border-radius: 50%;
    background: #bebebe;
    border-color: transparent;
    margin: 0 0.5rem 0.5rem;
    vertical-align: middle;
    -webkit-transition: all 0.11s;
    transition: all 0.11s;
    cursor: pointer;
    z-index: 9999;
    padding: 0.2rem 0 0;

    @media (hover: hover) {
      &:hover {
        svg {
          fill: $background !important;
        }
        background: #282828;
        border-color: transparent;
        fill: $background;
      }
    }

    &:active {
      background: #282828;
      border-color: transparent;
      fill: $background;
    }
  }
}

.copyright {
  border-top: 1px solid;
  padding: 1rem 0 0.5rem 1rem;
  flex: 0 0 auto;

  .copyright-table-data {
    width: 33%;
    height: 10%;
    background-color: rgb(37, 40, 42);
  }

  .small-link {
    font-family: "IBM Plex Sans", serif;
    font-size: 12px;
    color: $text-primary;
  }

  table {
    width: 100%;
  }

  tr {
    background-color: $background;
    height: 10%;
  }

  td {
    padding: 1rem 0.2rem 0.5rem !important;
    vertical-align: middle;

    &:last-child {
      text-align: left;
      width: 30%;
    }
  }
}

.ask-watson-and-back-to-top-buttons {
  width: auto;
}

#back-to-top.show {
  opacity: 1;
}

.iv-compatibility-product-footer {
  margin: 3rem 0 0 !important;
}

.iv-footer-wrapper {
  margin: 3rem 1rem 4rem;

  .iv-copy-trissel,
  .iv-copy-text {
    line-height: 1rem;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    font-size: 0.6875rem;
  }

  .iv-copy-trissel {
    border-top: 1px solid $iv-search-page-footer-border-color;
    padding-top: $spacing-06;
    padding-bottom: 1rem;
    font-weight: 700;
  }

  .iv-copy-text {
    padding-bottom: $spacing-06;
    border-bottom: 1px solid $iv-search-page-footer-border-color;
  }
}

// TODO : Add footer styles
.iv-compatibility-summary-footer {
}

.iv-compatibility-search-footer {
}

.iv-compatibility-product-footer {
}

.iv-compatibility-details-footer {
}

.conversation-pictograph {
  background-image: url(~/public/images/assistant_icon_20x20.png);
  background-repeat: no-repeat;
  background-position: right -1px bottom 47%;
  margin-left: 1.7rem;
  margin-right: 0.4rem;
  width: 18px;
}



//Adding this css property to display the entire text **Micromedex Assistant** in the overflow menu
/* common overflow menu css */
.cds--overflow-menu-options.cds--overflow-menu-options--open {
  width: 180px !important;
}