/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
/* variable declaration */
$chatbot-header-height: 3rem;
$chatbot-input-height: 2.5rem;
$chatbot-extra-padding: 7rem - ($chatbot-header-height + $chatbot-input-height);
$heading-color: #565353c4;
$selected-item-bg-color: #fcfcfc;
$drug-header-height: 3em;
$drug-select-height: 7em;
$drug-extra-padding: 16em - ($drug-header-height + $drug-select-height);
$selected-item-container-color: #3d3d3d;
$search-page-icon-color: #565656;
$search-page-selected-item-text-color: $text-on-color;
$tooltip-background-color: #bec3c0;
$tooltip-text-decoration-color: #ccc;
$tooltip-text-color: $icon-primary;
$chatbot-submit-button-color: #0353e9;
/* common css */
body {
  margin: 0;
  min-width: 320px;
  overflow: hidden;
  font-size: 0.875rem;
  font-family: "IBM Plex Sans", Helvetica Neue, Arial, sans-serif;
  height: 100vh; // default for browsers not supporting CSS var()
  height: calc(var(--vh, 1vh) * 100);
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  #root {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }
}
ul li {
  margin: 0;
}
p {
  margin-top: 0.5rem;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.bold {
  font-weight: bold;
}
* {
  box-sizing: content-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: normal;
}

.#{$prefix}--btn--sm {
  padding: 0 12px;
}
.heading-color.heading-font {
  font-size: 1.25rem;
  color: #575757;
  padding: 0.9rem 0 0.9rem 0;
}

#background-color {
  background: #1e0277;
  color: $background;
}
.#{$prefix}--row {
  padding-left: 0;
  margin: 0;
}
table td {
  padding: 0.2rem 0.5rem;
}
.#{$prefix}--col-lg-12 {
  padding: 0;
}
/* Accordion css */
.#{$prefix}--accordion.mdx-accordion {
  overflow: hidden;
  .#{$prefix}--accordion__item--active {
    overflow: auto;
  }
  .#{$prefix}--accordion__item {
    overflow: hidden;
  }
}
.#{$prefix}--accordion.drugInteractionAccordion,
.#{$prefix}--accordion.IVCompatibilityAccordion {
  overflow-y: hidden;
  padding-bottom: 16.5vh;
}
.#{$prefix}--accordion__item {
  -webkit-transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: all 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  padding: 0;
  clear: both;
  margin: 0;
  .#{$prefix}--accordion__arrow {
    margin: 0 1rem 0 0;
    // padding-right: 10px;
  }
  .#{$prefix}--accordion__title {
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 1.2rem;
    color: $text-primary;
    letter-spacing: 0.16px;
    width: 100%;
    text-align: left;
    z-index: 0;
    margin: 0 0 0 1rem;
    span {
      margin-bottom: 0;
    }
  }
}
.#{$prefix}--accordion__content p {
  margin: 0;
}
.#{$prefix}--accordion__item--active .#{$prefix}--accordion__content {
  width: 100%;
  padding: 0;
  visibility: visible;
  opacity: 1;
  -webkit-transition: height cubic-bezier(0, 0, 0.38, 0.9) 110ms, padding-top cubic-bezier(0, 0, 0.38, 0.9) 110ms,
    padding-bottom cubic-bezier(0, 0, 0.38, 0.9) 110ms;
  transition: height cubic-bezier(0, 0, 0.38, 0.9) 110ms, padding-top cubic-bezier(0, 0, 0.38, 0.9) 110ms,
    padding-bottom cubic-bezier(0, 0, 0.38, 0.9) 110ms;
  animation: 500ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.accordion-display-content {
  .#{$prefix}--accordion__item--expanding .#{$prefix}--accordion__content {
    display: none;
  }
}
.#{$prefix}--accordion__item:last-child {
  border-bottom: 1px solid #dcdcdc;
}
/* container layout css */
.main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 0;
  height: 100%;
  min-height: 0;
  .empty-container {
    margin-left: 0;
    margin-bottom: 0;
    position: relative;
    clear: both;
    padding: 3.4rem 5rem 0 0;
    background-color: $background;
  }
  .main-content {
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    min-height: 0;
    width: 100%;
    top: 0;
    .mdx-scroll-area {
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      min-height: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      position: relative;
      .mdx-header {
        padding: 0 1rem;
        margin: 0 0 0.5rem;
        h2 {
          word-wrap: break-word;
          font-size: 1.25rem;
          margin-top: 0;
          font-weight: 700;
          color: #7e8c8d;
        }
      }
      .mdx-header.border-line {
        margin: 0;
      }
      .mdx-inner-content {
        padding-bottom: 1rem;
        flex: 1 0 auto;
      }
      .drug-ivproduction-details-text {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
      }
    }
  }
  .brand-name-monograph {
    margin-top: 0.1rem;
    font-weight: 700;
    color: #7e8c8d;
  }
}

.tabs-inner-content {
  flex: 1 0 auto;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .main-container .main-content .mdx-scroll-area-ie {
    overflow-y: scroll;
  }
}

.mdx-header.grey-banner {
  background: #f3f3f3;
  border-top: 0.5em solid #bec3c9;
  margin: 0 0 0.5rem !important;
  padding: 1rem !important;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 10;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12),
    0px 2px 1px -1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12),
    0px 2px 1px -1px rgba(0, 0, 0, 0.2);
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: 0 auto;
  }
  label.input-header {
    font-size: 0.75rem;
    margin: 0.3rem 0 0;
    display: block;
  }
  .#{$prefix}--select-input {
    background: $background;
    &:focus::-ms-value {
      background: none;
      color: $text-primary;
    }
  }
}
/* Chatbot page css */
.chatbotParentContent {
  height: 100%;
  font-size: 1rem;
  .mdx-chatbot-parent-div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: 0;
    height: 100%;
    min-height: 0;
    .mdx-chatbot-filter-row {
      margin-left: 0rem;
      margin-bottom: 0.2rem;
      position: relative;
      clear: both;
      padding: 3.8rem 5rem 0 1rem;
      background-color: $background;
      border-bottom: 1px solid #d8d8d8;
      .mdx-filter-item {
        line-height: 1rem;
        margin: 0 0.2rem 0.5rem 0;
        padding: 3px 10px 3px;
        border: 1px solid #3d3d3d;
        .mdx-filter-text {
          font-size: 0.75rem;
        }
      }
      .mdx-filter-item.mdx-filter-item-clear {
        font-size: 0.75rem;
        top: 3.9rem;
        right: 0.55rem;
        background-color: #0072ce;
        color: $background;
        font-weight: bold;
        padding: 2px 10px 3px;
        border-color: #0072ce;
      }
    }
    .mdx-chatbot-filter-row.mdx-filter-count-zero {
      border-bottom: none;
    }
    .mdx-chatbot-filter-row.mdx-filter-disable > * {
      opacity: 0.4;
    }
    .mdx-chatbot-turns-row {
      margin: 0 0 3.7rem;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      min-height: 0;
      width: 100%;
      .mdx-chatbot-scrolling-div {
        flex-grow: 1;
        min-height: 0;
        overflow: auto;
        margin: 0rem;
        padding: 1rem 1.2rem 1rem $spacing-06;
        div {
          margin: 0.5rem 0 !important;
        }
      }
    }
    .mdx-chatbot-input-row {
      padding-bottom: 0rem;
      .#{$prefix}--text-input__field-wrapper {
        bottom: 0;
        position: fixed;
        width: calc(100% - 3.6rem);
        #chatbotUserInput {
          font-size: 1rem;
          height: 3.5rem;
        }
      }
      #mdx-right-icon {
        position: fixed;
        bottom: 0;
        right: 0;
        padding: 0.8rem;
        background-color: #0072ce;
        fill: $background;
        height: $spacing-07;
        width: $spacing-07;
        &:hover {
          background: $chatbot-submit-button-color;
        }
        path {
          d: path("M9.3 3.7l3.8 3.8H1v1h12.1l-3.8 3.8.7.7 5-5-5-5z");
        }
      }
    }
  }
}
.#{$prefix}--select-input {
  height: 2.1rem;
}
.loader-waiting {
  padding: 0 1rem;
  .loader-waiting p {
    margin: 0;
  }
}
.mdx-turn-group {
  margin: 0;
  border-bottom: none;
  padding: 0;
}
.chatbotResponseDisplay {
  li {
    a.pwa_link {
      width: auto;
    }
  }
  a.pwa_link {
    margin: 0 0 0 1rem;
    word-break: break-word !important;
    display: table;
    font-size: 0.875rem;
    line-height: 1.8rem;
    width: auto;
  }
}
.mdx-chatbot-turns-max-height,
.mdx-chatbot-input {
  width: 100%;
}
.mdx-header-chatbot button.#{$prefix}--header__action {
  padding: 0.3rem 1rem 0;
  width: auto;
  height: auto;
}
.mdx-chatbot-scrolling-div.chatbotResponses br {
  display: none;
}
/* Quick Answers Page & in-depth page css */
.sub-head-link {
  padding-bottom: 0.3rem;
  display: block;
}
.mdx-fda-uses-section-quick {
  margin-top: 0.8rem;
}
.noResultsDrugInteractionsHeader {
  display: inline-block;
  font-size: 1.25rem;
  margin: 0 1rem;
  font-weight: bold;
  color: #7e8c8d;
}
.mdx-in-depth-section-heading {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 10px;
}
.mdx-grouped-in-depth-section-heading {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 10px;
}
.mdx-grouped-in-depth-clickable-link {
  margin-left: 0.3em;
  color: blue;
  cursor: pointer;
  line-height: 1.2rem;
  display: table;
}
.mdx-grouped-parent-div {
  padding: 0 1rem;
}
.mdx-list-item-element .mdx-table-wrapper {
  overflow: auto;
  width: 100%;
  table {
    margin-bottom: 1rem;
    width: 98%;
  }
}
.mdx-in-depth-parent-div {
  margin-bottom: 20px;
}
.mdx-grouped-parent-div,
.mdx-in-depth-parent-div {
  > p {
    margin-top: 0.5rem;
  }
  a {
    font-size: 0.8rem;
  }
  > table,
  th,
  tr,
  td {
    border: solid 1px black;
    word-wrap: break-word;
    padding: 0.1rem;
    font-size: 0.8rem;
  }
}
.mdx-in-depth-parent-div .instructions-text.no-padding {
  padding: 0 1rem;
}
/* drug interaction page css */
.#{$prefix}--structured-list {
  display: table;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 0px;
  background-color: transparent;
}
.#{$prefix}--structured-list-td-iv-row-cell {
  vertical-align: middle;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.2rem;
  letter-spacing: 0.16px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: $text-primary;
  text-align: left;
  position: relative;
  max-width: 36rem;
  -webkit-transition: color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: color 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}
.#{$prefix}--structured-list-td-iv-row-cell.alnleft.prepstoreHead.#{$prefix}--structured-list-td {
  width: 100%;
}
.drug-accordion-container .#{$prefix}--accordion__content table,
.drug-accordion-container .#{$prefix}--accordion__content table tr {
  width: 100%;
  word-break: break-word;
  td:first-child {
    word-break: normal;
  }
}

svg.drug_interaction_fill {
  fill: #5290b2;
  padding: 0.1em;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
svg.di_fill_disabled {
  fill: #8c8c8c !important;
}
svg.drug_interaction_fill_sixteen {
  fill: #5290b2;
  margin-top: auto;
  padding: 0;
}
.accordionHeader .right_header {
  .#{$prefix}--tag--filter {
    margin: -0.1rem 1.5rem 0 0;
  }
  .#{$prefix}--btn.svgButton {
    margin: 0;
    padding: 0 0.2rem;
  }
}
#drugInteractionsBXAccordion .#{$prefix}--accordion__heading {
  padding: 0.4rem 0;
}
#secondaryAgentName {
  padding: 0.3rem 0;
}
.severity1 {
  height: 27px;
  margin-left: 5px;
}
td.severity1 p {
  display: inline-block;
  margin-left: 0.5rem !important;
}
#severity-text1 {
  font-weight: 300;
  color: $icon-primary;
  display: inline-block;
  margin: 2px 0 0 28px;
  font-size: 0.875rem;
  vertical-align: middle;
}
.drug-interaction-details-accordion .#{$prefix}--accordion__item p,
.mdx-drug-interaction-details-data .#{$prefix}--accordion__item p {
  margin-bottom: 10px;
}
#document-alignment {
  position: relative;
  padding-left: 38px;
  margin: 0 0 0.5rem;
}
svg.chevronleft16_fill,
svg.chevronleft16_fill-details {
  fill: #0062ff;
  padding: 0.1em 0.3em 0.1em 0.1em;
}
.severity-document {
  min-height: $spacing-07;
  line-height: normal !important;
}
#detail-interaction {
  background: #f3f3f3;
  padding: 0.5rem 0;
  width: 100%;
  caption {
    font-weight: 700;
    padding-left: 1rem;
    text-align: left;
    display: block;
  }
  .di-result_header_table td {
    padding: 0.7rem 1rem 0;
    word-break: break-all;
  }
  .iv-result_header_table td {
    padding: 0 0 0 1rem;
  }
}
.drug-interaction-header {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.drug-interaction-detail-header {
  width: 100%;
  > a {
    text-decoration: none;
  }
}
.clickableLink {
  color: #5290b2;
  display: inline-block;
}
.drug-interaction-modify-link,
.iv-modify-link {
  padding: 0.5rem 0;
  text-decoration: none;
  display: block;
  svg {
    margin-left: -7px;
    width: $spacing-07;
    height: $spacing-07;
    padding: 0;
  }
  svg,
  span {
    vertical-align: middle;
    display: inline-block;
  }
}
.mdx-inner-content .picklist-column-div {
  padding: 0 0 2rem 1rem;
  width: auto;
}
.drug-interaction-title-1 {
  color: $heading-color;
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1.4rem;
  span {
    font-size: 0.75rem;
    font-weight: 500;
    display: block;
  }
}
.drug-interaction-font {
  color: $heading-color;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 1em;
  font-weight: normal;
  vertical-align: baseline;
}
.#{$prefix}--grid {
  margin-right: auto;
  margin-left: auto;
  max-width: 99rem;
  padding-left: $spacing-07;
  padding-right: $spacing-07;
}
.no-padding-left {
  padding-left: 0 !important;
}
// .button-block {
//   width: 100%;
//   margin: 0 0 6px;
//   overflow: hidden;
//   .#{$prefix}--btn {
//     float: left;
//     padding: calc(0.375rem - 3px) 10px calc(0.375rem - 3px) 10px;
//     &:disabled {
//       outline: none;
//     }
//   }
//   .#{$prefix}--btn.#{$prefix}--btn--tertiary {
//     margin-right: 0.5rem;
//     width: 40%;
//   }
//   .#{$prefix}--btn.btnApply {
//     width: 32.5%;
//     border: 1px solid transparent;
//   }
// }
.document1 .tooltip-container {
  display: inline-block;
  vertical-align: middle;
  margin-left: 5px;
}
.picklist-tooltip-container.tooltip-container {
  padding-top: 4px;
  float: right;
  padding-left: 5px;
  width: 20px;
  text-align: right;
  span.tooltip-container,
  button {
    background-color: transparent;
    border-width: 0px;
  }
  .#{$prefix}--tooltip.picklist-tooltip {
    top: 31px !important;
    left: auto !important;
    right: -3px !important;
    text-align: left;
    .#{$prefix}--tooltip__caret {
      left: auto;
      right: 3px;
    }
    li {
      margin-bottom: 5px;
    }
  }
}

.main-content .#{$prefix}-tooltip-trigger,
.#{$prefix}-tooltip-trigger {
  margin: 1px 0 0 !important;
  svg {
    fill: #054ada;
  }
}
.#{$prefix}--label {
  font-size: 0.875rem;
}
#clear-button {
  background-color: $background;
  border-style: solid;
  border-color: #2860d5;
  color: #2860d5;
}
.border-line:after {
  display: block;
  height: 1px;
  content: " ";
  width: 100%;
  position: relative;
  left: 0;
  border-top: 6px solid #bec3c8;
  top: -0.4rem;
  margin: 0;
}
.heading-color {
  color: $heading-color;
}

.bottom-aln.drug-interactions-search-buttons {
  padding: 0 1rem;
  .bottom-aln:after {
    border-top: 1px solid #9e9e9e85;
    display: block;
    height: 1px;
    content: " ";
    width: 100%;
    position: relative;
    left: 0;
    top: 1.2em;
  }
  .#{$prefix}--btn.#{$prefix}--btn--primary,
  .#{$prefix}--btn.#{$prefix}--btn--tertiary {
    min-height: $spacing-07;
    padding: 0.375rem 30px 0.375rem 12px;
    width: 100%;
    max-width: 9rem;
    white-space: nowrap;
    margin-right: 1rem;
    height: auto;
  }
}

.tooltip-container button.#{$prefix}-tooltip-trigger svg {
  fill: #565656;
}

.filterModal .manualModalButtons .#{$prefix}--btn {
  padding: 0.875rem 0 0 0.5rem;
  height: 3.875rem;
  border-width: 1px;
}
.filterModal .modalContent {
  overflow-y: auto;
  height: 100vh;
  padding-left: 1em;
  padding-right: 0;
}
.filterModalParent {
  overflow: hidden;
}
.filterModalParent .filterModal {
  overflow: hidden;
}
.filterModal .#{$prefix}--modal-content {
  margin: 0;
  padding: 0 !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.iv-details-result {
  padding: 0 1rem;
  font-weight: bold;
  font-size: medium;
}
#ivDetailsPageContent {
  padding: 0 1rem;
  overflow: hidden;
  display: block;
}
.expandCollapseText {
  vertical-align: top;
  cursor: pointer;
}
.drug-details-text span#accordion-title-font {
  margin-bottom: 0px;
}
.drug-details-text span#accordion-title-font span:first-child {
  display: inline-block;
  margin-bottom: 0px;
}
.accordion-item-title-font {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 10px;
}
.productInfoReferenceDiv {
  padding-bottom: 1vh;
}
.referenceIdsIVProductInfoTitles {
  font-weight: normal;
}
.drug-ivproduction-details-text {
  word-break: break-word !important;

  .inline-header-iv-product-info {
    margin-bottom: -8px;
  }

  .full-indent-iv-product-info {
    text-indent: 40px;
  }
}
#severity-Major1,
#severity-Moderate1,
#severity-Minor1,
#severity-Contraindicated1,
#severity-Unknown1 {
  line-height: 2.2;
}
#severity-Major1 {
  background: url(~/public/images/severity_major23.png) no-repeat left 5px;
  color: #a10000;
  position: relative;
}
#severity-Moderate1 {
  background: url(~/public/images/severity_moderate23.png) no-repeat left 5px;
  color: #0072ce;
  position: relative;
}
#severity-Minor1 {
  background: url(~/public/images/severity_minor23.png) no-repeat left 5px;
  position: relative;
}
#severity-Contraindicated1 {
  background: url(~/public/images/severity_contra23.png) no-repeat left 5px;
  color: #a10000;
  position: relative;
}
#severity-Unknown1 {
  background: url(~/public/images/severity_unknown23.png) no-repeat left 5px;
  position: relative;
}
.instructions {
  font-size: 0.83rem;
  padding: 0 1rem 1.2rem 1rem;
  margin: 0;
}
.instructions-text {
  padding: 1.5rem 1rem;
  font-size: 0.875rem;
  margin: 0;
  font-family: "IBM Plex Sans";
}
.instructions-link {
  background-color: #3d3d3d;
  width: 220px;
  display: inline-block;
  padding: 0.3rem 0 0.3rem 0.5rem;
  font-weight: 700;
  margin: 0 0 2rem 1rem;
  color: $background;
  position: relative;
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 11px solid transparent;
    border-left: 21px solid #3d3d3d;
    border-bottom: 12px solid transparent;
    position: absolute;
    right: -20px;
    top: 0;
    display: inline-block;
  }
}

#drug_picklist, #allergy_picklist {
  width: 100%;
  height: auto;
  margin-top: 0.5rem;

  .picklist-label {
    padding: 0.5rem 0;
  }

  .picklist-row-div {
    padding-right: 25px;
    display: block !important;
    width: 37%;

    .picklist-input-field-wrapper {
      width: 100%;
      margin: 0;
      float: left !important;
      position: relative;
      border-bottom: 1px solid #8c8c8c;

      .picklist-settings-adjust-icon {
        position: absolute;
        left: 5px;
        top: 8px;
        width: 1.25rem;
        height: 1.25rem;
      }

      .picklist-close-button {
        float: right;
        padding: 7px 5px 0;
      }

      .picklist-input-field {
        padding: 0.2rem 3rem 0;
        font-size: 1rem;
        background: none;
        margin: 0;
      }
    }

    .picklist-tooltip-container.tooltip-container {
      position: absolute !important;
      top: 13px;
      right: 0;
    }
  }

  .picklist-selected-item {
    margin: 0.5rem 0.5rem 0 0;
    padding: 0.2rem;
    display: inline-flex;
    align-items: center;

    .#{$prefix}--definition-tooltip {
      word-break: break-word;
      left: 6px;
    }
  }

  .#{$prefix}--popover-content {
    word-break: break-word;
  }

  .picklist-selected-item-container {
    min-height: 2rem;

    .#{$prefix}--tooltip--bottom::before {
      border-color: $tooltip-background-color transparent !important;
      visibility: hidden;
    }

    .#{$prefix}--tooltip--visible::before {
      visibility: visible;
    }

    .#{$prefix}--tooltip__trigger--definition {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-style: dashed;
      text-decoration-color: $tooltip-text-decoration-color;
      border-bottom: none;
    }

    .#{$prefix}--assistive-text {
      color: $tooltip-text-color !important;
      background: $tooltip-background-color !important;
      overflow: auto;
    }

    .#{$prefix}--tooltip__trigger {
      border-bottom: none;

      svg {
        fill: $search-page-icon-color !important;
      }
    }

    .picklist-selected-item-text {
      font-family: IBM Plex Sans, Helvetica Neue, Arial, sans-serif;
      font-size: 0.75rem;
      color: $search-page-selected-item-text-color !important;
      line-height: 1.2rem;
      margin-left: 0.25rem !important;
      padding: 0.1rem 0.3rem 0.15rem !important;
    }

    .picklist-selected-item-big-text {
      cursor: pointer;
      text-decoration: underline;
      text-decoration-style: dashed;
      text-decoration-color: $tooltip-text-decoration-color;
      border-bottom: none;
    }

    .#{$prefix}--assistive-text {
      color: $tooltip-text-color !important;
      background: $tooltip-background-color !important;
      overflow: auto;
      word-break: break-all;
    }
  }
}

.#{$prefix}--tooltip--hidden::before {
  display: none;
}

.#{$prefix}--toast-notification {
  width: auto;
  margin-bottom: 1rem;
}
.#{$prefix}--toast-notification__subtitle {
  margin-bottom: 0;
}
.mdx-para-element {
  margin-top: 0.5rem;
}
.mdx-para-text {
  line-height: 1.2rem;
  font-size: 13px;
  overflow-wrap: break-word;
}
.mdx-title-text {
  line-height: 1.2rem;
  font-size: 13px;
  overflow-wrap: break-word;
}
.mdx-section-child {
  margin-top: 0.5rem;
}
.mdx-section-heading {
  margin-top: 0.5rem;
  font-size: 1em;
  padding: 0;
}
.mdx-label-element {
  font-weight: 700;
}
.mdx-in-depth-section-heading-title {
  font-size: 16px;
  color: #616161;
  letter-spacing: 0;
  font-weight: 700;
}
.mdx-section-title {
  display: inline-block;
  font-weight: bold;
  color: #7e8c8d;
  margin-bottom: 10px;
}
.mdx-formula-div {
  padding-top: 0.5rem;
  font-family: monospace;
}
.mdx-xref-citation {
  color: blue;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.mdx-emph-bold {
  font-weight: bold;
  margin-right: 0.3rem;
}
.mdx-emph-italic {
  font-style: italic;
}
.mdx-xref-hidden {
  color: blue;
  margin-left: 0.3rem;
  text-decoration: none;
  margin-right: 0.3rem;
  margin-bottom: 0.4rem;
  line-height: 1.3rem;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.mdx-citation-text {
  margin-top: 1.4rem;
  .mdx-xref-link-item {
    margin-left: 5px;
    word-break: normal;
  }
}
.#{$prefix}--modal-content .mdx-citation-text {
  padding: 1rem 0;
  margin: 0;
}
.mdx-citation-text-content {
  line-height: 1.3rem;
  margin-top: 1rem;
}
.subscriptionHeadingBlue {
  margin-bottom: 5px;
  margin-top: 10px;
  font-size: 1.1em;
  line-height: 1.2em;
  padding: 5px;
  width: 98%;
  text-indent: 5px;
  background-color: #2e008b;
  border-left: none;
  border-right: none;
  border-top: none;
  color: $background;
}
.SubscriptionHeadingBlack {
  margin-bottom: 5px;
  margin-top: 10px;
  font-size: 1.1em;
  line-height: 1.2em;
  padding: 5px;
  width: 98%;
  text-indent: 5px;
  background-color: #25282a;
  border-left: none;
  border-right: none;
  border-top: none;
  color: $background;
}
.subscriptionTable {
  width: 96%;
  cellpadding: 2px;
  cellspacing: 0;
  border: 0;
}
.subscriptionTableData {
  width: 48%;
}

#bottom-border-line {
  clear: both;
  border-bottom: 1px solid #f3f3f3;
  background: #f3f3f3;
  padding: 0 1rem;
}
.drug-accordion-title .alnright {
  float: right;
}
#bottom-border-line svg.chevronleft16_fill-details {
  padding-right: 0;
}

/* IV compatibility page css */
.mdx-label-bold {
  font-weight: bold;
}

.section-title {
  font-weight: bold;
}

.sub_plans {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin: 5px 0px;
  margin-top: 20px;
}

.sub_plans_text {
  float: left;
}

.display-to-end svg {
  vertical-align: middle;
}

svg.iv-compat-filter-fill {
  fill: #5290b2;
  padding: 0.1em;
}
.iv-compat-checkbox-inner-container {
  padding-left: 1em;
}

#collapse-border-line {
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  margin: 0;
  padding-bottom: 0.3rem;
  padding-top: 0.2rem;
}

.mdx-ivcompatibility-data.main-container .main-content .mdx-scroll-area .mdx-header {
  a,
  h4 {
    padding: 0 0.5rem;
  }
}

.mdx-ivcompatibility-data {
  .#{$prefix}--accordion__heading svg.#{$prefix}--accordion__arrow {
    margin: 0 1rem 0 0;
  }
  table {
    width: 100%;
    td {
      padding: 0.3rem 0.5rem;
      a {
        text-decoration: none;
      }
      b {
        font-weight: bold;
      }
    }
  }
  .iv-compat-summary-accordion-first-line {
    clear: both;
    padding: 0 0.5rem;
    .iv-compat-summary-accordion-title {
      display: inline-block;
      vertical-align: middle;
      width: 80%;
      padding: 0;
    }
    .iv-compat-filter-component {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      text-align: right;
      padding: 0;
      margin: 0 0 0.5rem;
    }
  }
}
.iv-result-row {
  vertical-align: middle;
  min-width: 4.5rem;
  max-width: 5rem;
  text-align: left;
  color: #0072ce;
  line-height: 1.2rem;
  font-size: 0.875rem;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 0;

  a {
    text-decoration: none;
  }
}

td.accordion-first {
  clear: both;
  .alnleft {
    float: left;
    width: 73%;
  }
  .alnright {
    float: right;
    width: 27%;
    margin: 0;
    text-align: right;
  }
}
.drug-interaction-selectedtable td.alnleft {
  width: 15%;
}
.drug-interaction-selectedtable td.alnright {
  width: 75%;
}
.#{$prefix}--structured-list-pwa.accordion-first {
  clear: both;
  .alnleft {
    float: left;
    width: 75vw;
  }
  .alnright {
    float: right;
    margin: 0;
    padding: 10px 0;
  }
}
#title-border-line {
  span.alnright {
    margin: 0 0.5rem 0 0;
  }
  span.mdx-accordion-subTitle {
    padding-left: 0.5rem;
  }
}

.interaction-substances-dropdown {
  cursor: pointer;
  overflow: hidden;
  span.alnleft {
    float: left;
  }
  span.alnright {
    float: right;
  }
}

.filterPanelTabSelected.accordionHeader {
  text-align: right;
  display: block;
  .#{$prefix}--checkbox-label-text {
    color: $icon-primary;
    display: inline-block;
    vertical-align: middle;
  }
}

.accordionHeader {
  display: flex;
  justify-content: space-around;
  padding: 0 1rem;
  .expandCollapseLink {
    display: flex;
    justify-content: left;
    text-align: center;
    .expandCollapseText,
    .expandCollapseChevron {
      display: inline-block;
      vertical-align: middle;
    }
    .expandCollapseText {
      padding-left: 0.5rem;
      padding-top: 0.1rem;
    }
  }
  .left_header {
    vertical-align: middle;
    width: 50%;
    padding-left: 0;
    .clickableLink {
      height: 100%;
    }
  }
  .right_header {
    padding-right: 0;
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
  .#{$prefix}--btn.svgButton {
    margin: 0 1em 0 0;
    padding: 0 0 0 0;
    min-height: 0px;
    background-color: transparent;
    border-width: 0px;
  }
  .#{$prefix}--btn--primary:focus {
    outline-color: transparent;
  }
  .filterPanelTabUnselected,
  .filterPanelTabSelected {
    position: relative;
    z-index: 0;
    width: $spacing-06;
    height: $spacing-06;
    margin: 0 1.5rem 0 0 !important;
    top: 0px;
    left: 0px;
  }
  .filterPanelTabSelected {
    background-color: #f3f3f3;
    z-index: 2;
    box-shadow: 0 0px 2.5px 2px rgba(0, 0, 0, 0.18);
  }
  .filterPanelTabSelected b {
    background: #f3f3f3;
    height: 30px;
    display: block;
    font-weight: normal;
  }
  .filterPanel {
    background-color: #f3f3f3;
    position: absolute;
    width: 250px;
    margin-top: 2px;
    height: auto;
    top: 23px;
    left: -226px;
    z-index: 1;
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.3);
    .filterPanelContent {
      padding: 1em;
      h2 {
        color: $text-primary;
        line-height: 1.2rem;
        font-size: 0.875rem;
        margin: 1.3rem 0 0 0;
        font-weight: 700;
      }
      .button-block {
        margin-top: $spacing-06;
        .#{$prefix}--btn--sm {
          min-height: $spacing-06;
          line-height: $spacing-06;
        }
      }
    }
  }
  .clickableLink {
    color: #0072ce;
    cursor: pointer;
  }
  .filterButton {
    background-color: #bcbcbc;
    border-width: 0;
  }
}
.#{$prefix}--side-nav__overlay {
  z-index: 6000;
}
.iv-compat-filter-content {
  display: block;
  .#{$prefix}--form-item bx--checkbox-wrapper {
    display: inline-block;
    vertical-align: middle;
  }
  img {
    width: 20px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 7px 0 0;
  }
}
#accordion-title-font {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 10px;
}
.drug-interaction-sections {
  padding: 0 1rem;
}
.drug-interaction-sections div,
.drug-interaction-sections div p {
  font-size: inherit;
  margin: 0;
  line-height: 1.4;
}
.drug-interaction-sections h5.drug-details-page-title {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 1rem;
  font-size: 0.9rem;
  padding: 0 !important;
}
.drug-interaction-sections h4 {
  font-size: 0.8rem;
  margin: 0.5rem 0;
  font-weight: bold;
}
.document1 .drug-details-page-title,
.severity1 .drug-details-page-title {
  margin-top: 0;
}
.drug-interaction-sections .document1 {
  h5.drug-details-page-title {
    margin: 0;
  }
  .tooltip-container .info-icon .#{$prefix}--tooltip__trigger {
    margin: 4px 0 0 !important;
  }
}
.drug-interaction-reference-accordion,
.drug-interaction-literature-accordion {
  margin-top: 0.5rem;
  #accordion-title-font {
    margin: 0.2rem 0;
    font-size: 0.875rem;
    font-weight: 400;
  }
  .#{$prefix}--accordion__content p {
    line-height: 1.4;
    padding: 0.5rem $spacing-06;
  }
  p {
    font-size: 0.875rem;
    i {
      display: block;
      padding: 0.5rem 1rem;
      font-style: italic;
    }
  }
}
.drug-details-text {
  font: 13px arial, sans-serif;
  line-height: 1.2rem;
  b {
    font-weight: bold;
  }
}
#accordion-title-font.accordion-title-tpn,
.accordion-content-tpn {
  margin: 0.5rem 0;
  display: block;
}
#productInfoPageReferenceBXAccordion .#{$prefix}--accordion__content,
.drug-interaction-details-accordion .#{$prefix}--accordion__content {
  padding: 0;
}
.iv-details-summary-table {
  .iv-details-column1,
  .iv-details-column2 {
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
  }
  .iv-details-column1 {
    width: 15%;
  }
  .iv-details-column2 b {
    margin: 0.3rem 0 0;
    display: block;
  }
  .iv-details-column2 b:first-child {
    margin-top: 0;
  }
}
.tpn-result-summary {
  padding-left: 0.5rem;
}
td.alnright a.display-to-end {
  display: block;
  text-align: left;
  width: max-content;
}
td.alnright a.display-to-end-results {
  display: block;
  text-align: right;
}
td.alnright.interaction-view-detail {
  text-align: right;
  a.display-to-end-results {
    display: inline-block;
  }
}
td.table-ivcompatible-right {
  text-align: right;
}
td.table-ivcompatible.table-rating {
  padding-top: 0.7rem;
}

.lastAccordion .alnright-tpnTna {
  text-align: right;
  margin-right: 0;
  width: 40%;
  padding-bottom: 0;
  float: right;
}
.table-ivcompatible-content-right {
  text-align: right;
  width: 25%;
  float: right;
}
.table-ivcompatible-content-left {
  float: left;
  width: 72%;
}
svg.iv_compatibility_fill {
  fill: #5290b2;
  padding: 0.1em;
  width: 16px;
  height: 16px;
  vertical-align: sub;
}

.mdx-drug-result span:first-child {
  float: left;
  width: 75%;
}
.mdx-drug-result span.alnright {
  float: right;
  margin: 0;
  width: 25%;
}
.mdx-drug-result span.ysite-txt {
  width: 64%;
}
.mdx-drug-result.tpn-drug-result span:first-child {
  width: 65%;
}
.mdx-drug-result.tpn-drug-result span.alnright {
  width: 35%;
}
.mdx-interaction-search-data .picklist-row-div .picklist-input-field {
  width: 89%;
}

//Popup model CSS
.#{$prefix}--modal-container {
  background: $background;
}
.filterModal .#{$prefix}--modal-container {
  overflow: visible;
}
.#{$prefix}--modal-container .#{$prefix}--modal-header {
  border-bottom: 1px solid #d8d8d8;
  margin: 0;
  position: relative;
  padding: 0.6rem 1rem 0.5rem;
  h3.#{$prefix}--modal-header__heading {
    color: #565656;
    margin: 0;
    font-weight: 700;
    font-size: 0.95rem;
    font-family: Arial, Helvetica, sans-serif;
    white-space: nowrap;
  }
  .#{$prefix}--modal-close {
    height: 40px;
    width: 40px;
    padding: 0;
    border: none !important;
    .#{$prefix}--modal-close__icon {
      fill: $icon-primary;
      height: $spacing-07;
      width: $spacing-07;
      margin: 3px 0 0;
    }
  }
}
.#{$prefix}--modal-content {
  padding: 0 1rem;
  margin-bottom: 0;
  text-align: left;
}

.#{$prefix}--modal-content h5 {
  margin: 0 0 0 1.5px;
  padding: 0;
}

.#{$prefix}--modal-container .#{$prefix}--modal-header .#{$prefix}--modal-close,
.#{$prefix}--modal-container .#{$prefix}--modal-header .#{$prefix}--modal-close:hover,
.#{$prefix}--modal-container .#{$prefix}--modal-header .#{$prefix}--modal-close:active {
  border: 0 !important;
  outline: none !important;
}
.drug-model-header {
  font-weight: 700;
  margin: 1.8rem 0 1rem 0;
  font-size: 0.875rem;
}
.#{$prefix}--modal-content #severity-Contraindicated,
.#{$prefix}--modal-content #severity-Major,
.#{$prefix}--modal-content #severity-Minor,
.#{$prefix}--modal-content #severity-Moderate,
.#{$prefix}--modal-content #severity-Unknown {
  min-height: auto;
}
.#{$prefix}--modal-content #severity-text1 {
  font-weight: 700;
  margin-top: 0;
  vertical-align: top;
}
.#{$prefix}--modal-content #document-alignment {
  font-size: 0.875rem;
  padding-left: 28px;
}
.mdx-drug-data-header-col {
  margin-left: 0rem;
  margin-right: -0.3rem;
  padding-right: 0.5rem;
  .#{$prefix}--header {
    right: -1.4rem;
    .#{$prefix}--header__action {
      margin-right: 1rem;
      padding: 0.3rem 1rem 0;
      width: auto;
      height: auto;
    }
  }
}

.related-result-accordion {
  width: 100%;
  margin-bottom: 20px;
  .#{$prefix}--accordion__item--active .#{$prefix}--accordion__content {
    padding-bottom: 10px;
  }
}
.drug-consults-references-popup-link {
  color: blue;
  cursor: pointer;
  padding-left: 50px;
}

.related-result-accordion-no-data {
  padding-left: 50px;
  span {
    display: block;
    margin-top: -18px;
    margin-left: 20px;
  }
}

.related-results-loading {
  padding-left: 35px;
}

.drug-consults-links-popup-loading {
  margin-left: -1rem;
}

.drug-consults-loading {
  margin-left: -0.5rem;
}

.drug-monograph-loading {
  padding-left: 1rem;
}

.drug-consults-references-link {
  color: blue;
  cursor: pointer;
}
.drug-consults-references-accordion {
  .#{$prefix}--accordion__content {
    margin-top: 10px;
  }
  .#{$prefix}--accordion__title {
    margin: 0;
  }
  button.#{$prefix}--accordion__heading {
    flex-direction: row;
    padding-bottom: 0.1rem;
    &:focus:before {
      border: none;
    }
    &:hover:before {
      background: none;
    }
    .#{$prefix}--accordion__arrow {
      padding-right: 0;
      margin-left: 10px;
    }
  }
  .mdx-drug-points-waiting {
    padding-left: 50px;
  }
}
.drug-consults-links-list {
  padding: 10px;
  list-style: disc;
  .mdx-xref-link-item {
    text-decoration: none;
  }
  li {
    margin-bottom: 10px;
  }
}

.mdx-patient-handouts {
  padding: 1rem 1.5rem 1.5rem 1rem;
}

.no-subscription-header {
  h5 {
    padding-bottom: 1rem;
  }
}
.no-subscription-message,
.no-subscription-button {
  padding: 0 1rem 1.2rem 1rem;
}

.#{$prefix}--definition-term {
  border-bottom: 0px;
}

.drug-interaction-page-titles {
  color: rgba(86, 83, 83, 0.768627451)!important;
  font-family: Arial, Helvetica, sans-serif!important;
  font-size: 1.35rem!important;
  margin: 1rem 1rem 0.77rem 0!important;
  font-weight: 700!important;
  span {
    display: inline;
    font-weight: 700!important;
  }
}

/* media queries */

@media only screen and (max-width: 1090px) {
  .picklist-row-div {
    width: 40%;
  }
}
@media only screen and (max-width: 1023px) {
  body #root > .mdx-drug-interaction-data {
    word-break: break-word;
  }
}
@media only screen and (max-width: 870px) {
  .mdx-interaction-search-data .picklist-row-div .picklist-input-field-wrapper {
    width: 96%;
  }
  #drug_picklist .picklist-row-div, #allergy_picklist .picklist-row-div {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .mdx-header-chatbot {
    .#{$prefix}--header__name {
      padding: 0 0 0 0.6rem;
    }
    button#log-out-button {
      padding: 0 0.5rem;
    }
    button.#{$prefix}--header__action {
      padding: 0.3rem 0.6rem 0;
    }
  }
  #log-out-button #mdx-logout {
    display: block;
    fill: $background;
    width: 1.3rem;
    height: 1.3rem;
  }
  #log-out-button .logout-txt {
    display: none;
  }
  .mdx-citation-text .mdx-xref-link-item {
    margin-left: 5px;
  }
  .#{$prefix}--structured-list-pwa.accordion-first .alnleft {
    width: 61vw;
  }
  .#{$prefix}--structured-list-pwa.accordion-first .alnright {
    width: 10vw;
  }
  .mdx-interaction-search-data .picklist-row-div .picklist-input-field-wrapper {
    width: 95%;
  }
  .picklist-tooltip-container {
    width: 4%;
  }
  #drug_picklist .picklist-row-div, #allergy_picklist .picklist-row-div {
    width: 88%;
  }
  .picklist-row-div:not(.iv-tooltip-container) .picklist-input-field-wrapper .picklist-input-field {
    padding: 0.2rem 0 0 3rem !important;
    font-size: 0.875rem;
  }
}
@media only screen and (max-width: 570px) {
  .mdx-scroll-area {
    height: 79.7vh;
  }
  .mdx-para-element {
    margin-top: 0.5rem;
    word-wrap: break-word;
  }
}
@media only screen and (max-width: 479px) {
  .mdx-scroll-area .drug-accordion-container table td {
    padding: 0.2rem;
    word-break: break-word;
  }
  .mdx-interaction-search-data .picklist-row-div .picklist-input-field-wrapper {
    width: 90%;
  }
  .mdx-interaction-search-data .picklist-row-div .picklist-input-field {
    width: 78%;
  }
  .iv-details-summary-table .iv-details-column1 {
    width: 30%;
  }
  #drugInteractionsBXAccordion .#{$prefix}--accordion__item .#{$prefix}--accordion__content table td:first-child,
  #drugInteractionsBXAccordion .#{$prefix}--accordion__item .#{$prefix}--accordion__content table tr td:first-child {
    word-break: break-word;
    width: 35%;
  }
  .mdx-ivcompatibility-data .iv-compat-summary-accordion-first-line .iv-compat-summary-accordion-title {
    width: 70%;
  }
  /*.mdx-ivcompatibility-data .iv-compat-summary-accordion-first-line .iv-compat-filter-component {
    width: 30%;
  }*/
  .#{$prefix}--structured-list-pwa.accordion-first .alnleft {
    width: 50vw;
  }
  .#{$prefix}--structured-list-pwa.accordion-first .alnright {
    width: 18vw;
  }
}
@media only screen and (max-width: 359px) {
  .mdx-interaction-search-data .picklist-row-div .picklist-input-field {
    width: 79%;
  }
  .drug-interaction-modify-link svg {
    vertical-align: middle;
  }
  .mdx-interaction-search-data .#{$prefix}--btn--sm {
    padding: calc(0.375rem - 3px) 40px calc(0.375rem - 3px) 12px;
  }
  .mdx-ivcompatibility-data .iv-compat-summary-accordion-first-line .iv-compat-summary-accordion-title {
    width: 66%;
  }
  /*.mdx-ivcompatibility-data .iv-compat-summary-accordion-first-line .iv-compat-filter-component {
    width: 34%;
  }*/
  td.accordion-first .alnleft {
    width: 70%;
  }
  td.accordion-first .alnright {
    width: 30%;
  }
  .#{$prefix}--structured-list-pwa.accordion-first .alnleft {
    width: 43vw;
  }
  .#{$prefix}--structured-list-pwa.accordion-first .alnright {
    width: 23vw;
  }
  .mdx-grouped-parent-div .mdx-list-item-element table {
    td {
      font-size: 0.8rem;
      padding: 0 0 0 0.1rem;
    }
  }
  .mdx-para-element {
    margin-top: 0.5rem;
    word-wrap: break-word;
  }
  .mdx-scroll-area .drug-accordion-container table td {
    padding: 0.2rem;
    word-break: break-word;
  }
  #drugInteractionsBXAccordion .#{$prefix}--accordion__item .#{$prefix}--accordion__content table td:first-child,
  #drugInteractionsBXAccordion .#{$prefix}--accordion__item .#{$prefix}--accordion__content table tr td:first-child {
    word-break: break-word;
    width: 40%;
  }
  #drug_picklist .picklist-row-div, #allergy_picklist .picklist-row-div {
    width: 80%;
  }
}

// IPad Configurations
// iPad in portrait & landscape
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* STYLES GO HERE */
}
//iPad in landscape
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* STYLES GO HERE */
}
//iPad in portrait
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  /* STYLES GO HERE */
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
}

/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .mdx-scroll-area {
    height: 80vh;
  }
}

/* Portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .mdx-scroll-area {
    height: 85vh;
  }
}

/* Landscape */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 834px) and (max-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
@media only screen and (min-width: 834px) and (max-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
@media only screen and (min-width: 1112px) and (max-width: 1112px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .mdx-scroll-area {
    height: 88vh;
  }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
@media only screen and (min-width: 1366px) and (max-width: 1366px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
  .mdx-scroll-area {
    height: 84vh;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  td.alnright a.display-to-end {
    display: inline-block;
  }
  .mdx-grouped-in-depth-clickable-link {
    display: table;
  }
}

@media screen and (min-height: 1024px) and (max-height: 1366px) {
  .filterModal .modalContent {
    height: 100%;
  }
}
