/* ***************************************************************** */
/*                                                                   */
/* Licensed Materials - Property of IBM                              */
/*                                                                   */
/* (C) Copyright IBM Corp. 2022                                      */
/*                                                                   */
/* ***************************************************************** */
//$feature-flags: (
//        ui-shell: true,
//        grid-columns-16: true
//);

@import '@carbon/styles';
@import '@carbon/type';
@import '@carbon/layout';


body {
  margin: 0;
  padding: 0;
//  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
//    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
//    sans-serif;
//  -webkit-font-smoothing: antialiased;
//  -moz-osx-font-smoothing: grayscale;
}

//code {
//  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//    monospace;
//}